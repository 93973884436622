
import { Options, Vue } from "vue-property-decorator";
import * as api from "@/api/inspection";
import File from "@/api/file";

@Options({})
export default class extends Vue {
  private submitform = {
    status: null,
    wayId: "",
    remark: "",
    pointId: "",
    fileUrl: "",
  };
  private uploader: any[] = [];

  private isTrue = false;

  mounted() {
    this.isTrue = true;
    this.submitform.pointId = this.$route.query.pointId as any;
    this.submitform.wayId = this.$route.query.wayId as any;
  }

  afterRead(files: any) {
    const that = this
    let filearr = null;
    if (!Array.isArray(files)) {
      filearr = [files];
    } else {
      filearr = [...files];
    }
    filearr.map((file: any) => {
      file.status = "uploading";
      file.message = "上传中...";
      File.uploadFile(
        "photographmobile/",
        file.file.name,
        file.file,
        true
      ).then((res: any) => {
        that.isTrue = false;
        file.status = "done";
        file.id = res.data.id;
        file.url = res.data.url;
      });
    });
  }

  protected onSubmit() {
	this.isTrue = true;
    let arr: any[] = [];
    this.uploader.map((li) => {
      arr.push(li.url);
    });
    this.submitform.fileUrl = arr.join(",");
    api.saveRecord(this.submitform).then((res: any) => {
      if (res.code === 0) {
        this.$toast.success({
          message: res.msg,
          forbidClick: true,
          onClose: () => {
            this.$router.go(-1);
          },
        });
      } else {
        this.$toast.fail(res.msg);
      }
    });
  }
}
