import request from "@/utils/request";

// 巡检历史记录
export function inspectionPointHistory(data: any) {
  return request({
    url: "/project/mobile/inspection/pointHistory",
    method: "post",
    data: data,
  });
}

//获取签名
export function mobileInspectionGetTicket(data: any) {
  return request({
    url: "/project/mobile/inspection/getTicket",
    method: "get",
    params: data,
  });
}

// 手机查询巡检点列表
export function listPoint(data: any) {
  return request({
    url: "/project/mobile/inspection/pagePoint",
    method: "post",
    data: data,
  });
}

// 查询巡检路线列表
export function pageWay(arg: any) {
  return request({
    url: "/project/mobile/inspection/pageWay",
    method: "post",
    data: arg,
  });
}

// 条形码解析
export function mobileInspectionDecode(arg: any) {
  return request({
    url: "project/mobile/inspection/decode",
    method: "POST",
    data: arg,
  });
}

// 巡检记录提交
export function saveRecord(arg: any) {
  return request({
    url: "/project/mobile/inspection/saveRecord",
    method: "post",
    data: arg,
  });
}

// 巡检路线巡检点展示
export function listPointAll(arg: any) {
  return request({
    url: "/project/mobile/inspection/listPoint",
    method: "post",
    data: arg,
  });
}
